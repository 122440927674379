<template>
  <FieldDefinition :definition="template.definition"></FieldDefinition>
  <p v-if="field.value">
    {{field.value}}
  </p>
</template>

<script>
import FieldDefinition from './FieldDefinition'
export default {
  name: 'FieldInteger',
  components: { FieldDefinition },
  props: {
    template: {
      type: Object
    },
    field: {
      type: Object
    }
  }
}
</script>

<style scoped>

</style>
