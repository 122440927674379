<template>
  <router-view/>
</template>

<script>
export default {
  name: 'Projects',
  props: {
    unitId: {
      required: true,
      type: String
    }
  }
}
</script>

<style scoped>
</style>
