import axios from 'axios'

const state = () => ({
  current: null,
  documentation: null,
  generalElementLinks: {},
  buildingElementLinks: {},
  buildingElementProducts: {}
})

const getters = {
  generalElementLinks: (state) => (elementId) => {
    return state.generalElementLinks[elementId]
  },
  buildingElementLinks: (state) => (elementId) => {
    return state.buildingElementLinks[elementId]
  },
  buildingElementProducts: (state) => (elementId) => {
    return state.buildingElementProducts[elementId]
  },
  projectPdfUrl: (state) => ({ unitId, projectId }) => {
    return axios.defaults.baseURL + `/business/${unitId}/projects/${projectId}/mom/pdf/external-system/default-config?token=` + axios.defaults.token
  }
}

const actions = {
  getProject ({ commit }, { unitId, projectId }) {
    return axios.get(`/business/${unitId}/projects/${projectId}`).then((response) => {
      commit('setProject', response.data)
    })
  },
  /**
   * Return same object as getProject but server do not require token.
   *
   * @param commit
   * @param unitId
   * @param projectId
   * @returns {Promise<AxiosResponse<T> | never>}
   */
  getProjectInfo ({ commit }, { unitId, projectId }) {
    return axios.get(`/business/${unitId}/projects/${projectId}/info`).then((response) => {
      commit('setProject', response.data)
    })
  },
  getMomDocumentation ({ commit }, { unitId, projectId }) {
    return axios.get(`/business/${unitId}/projects/${projectId}/mom/documentation`).then((response) => {
      commit('setMomDocumentation', response.data)
    })
  },
  getGeneralElementLinks ({ commit }, { unitId, projectId, elementId }) {
    return axios.get(`/business/${unitId}/projects/${projectId}/mom/general-documentation/${elementId}/links`).then((response) => {
      commit('setGeneralElementLinks', { links: response.data, elementId: elementId })
    })
  },
  getBuildingElementLinks ({ commit }, { unitId, projectId, elementId }) {
    return axios.get(`/business/${unitId}/projects/${projectId}/mom/building-documentation/${elementId}/links`).then((response) => {
      commit('setBuildingElementLinks', { links: response.data, elementId: elementId })
    })
  },
  getBuildingElementProducts ({ commit }, { unitId, projectId, elementId }) {
    return axios.get(`/business/${unitId}/projects/${projectId}/mom/building-documentation/${elementId}/products`).then((response) => {
      commit('setBuildingElementProducts', { products: response.data, elementId: elementId })
    })
  }
}

const mutations = {
  setProject (state, project) {
    state.current = project
  },
  setMomDocumentation (state, documentation) {
    state.documentation = documentation
  },
  setGeneralElementLinks (state, { links, elementId }) {
    state.generalElementLinks[elementId] = links
  },
  setBuildingElementLinks (state, { links, elementId }) {
    state.buildingElementLinks[elementId] = links
  },
  setBuildingElementProducts (state, { products, elementId }) {
    state.buildingElementProducts[elementId] = products
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
