<template>
  <h3 class="title is-3" v-if="template.definition.name">
    {{template.definition.name}}
  </h3>
  <h4 class="tile is-4" v-if="template.definition.description">
    {{template.definition.description}}
  </h4>
  <div class="columns" v-for="rowFieldTemplate in fieldsMatrix" :key="rowFieldTemplate">
    <div class="column" :class="cssClass(innerFieldTemplate)" v-for="innerFieldTemplate in rowFieldTemplate" :key="innerFieldTemplate.id">
      <div v-if="'Label' === innerFieldTemplate.metadata.type">
        <FieldLabel :template="innerFieldTemplate"></FieldLabel>
      </div>
      <div v-if="'ShortText' === innerFieldTemplate.metadata.type">
        <FieldShortText :template="innerFieldTemplate" :field="fieldFrom(innerFieldTemplate)"></FieldShortText>
      </div>
      <div v-if="'LongText' === innerFieldTemplate.metadata.type">
        <FieldLongText :template="innerFieldTemplate" :field="fieldFrom(innerFieldTemplate)"></FieldLongText>
      </div>
      <div v-if="'RichText' === innerFieldTemplate.metadata.type">
        <FieldRichText :template="innerFieldTemplate" :field="fieldFrom(innerFieldTemplate)"></FieldRichText>
      </div>
      <div v-if="'Integer' === innerFieldTemplate.metadata.type">
        <FieldInteger :template="innerFieldTemplate" :field="fieldFrom(innerFieldTemplate)"></FieldInteger>
      </div>
    </div>
  </div>
</template>

<script>
import FieldLabel from './FieldLabel'
import FieldShortText from './FieldShortText'
import FieldLongText from './FieldLongText'
import FieldRichText from './FieldRichText'
import FieldInteger from './FieldInteger'

export default {
  name: 'FieldGroup',
  components: { FieldInteger, FieldRichText, FieldLabel, FieldShortText, FieldLongText },
  props: {
    template: {
      type: Object
    },
    field: {
      type: Object
    }
  },
  methods: {
    columnMatrix () {
      const fieldArray = this.template.defaultValue.groupFields
      const matrix = []
      let rowFields = []
      fieldArray.forEach(field => {
        console.log(field.metadata.type + ':' + field.definition.name)
        const fieldRowIndex = field.annotation.rowIndex
        if (matrix.length + 1 < fieldRowIndex) {
          matrix.push(rowFields)
          rowFields = []
        }
        rowFields.push(field)
      })
      if (rowFields.length > 0) {
        matrix.push(rowFields)
      }
      return matrix
    },
    gridMatrix () {
      const columns = this.template.annotation.rowFieldsCount
      const fieldArray = this.template.defaultValue.groupFields
      const fieldsLenght = fieldArray.length
      const rows = Math.ceil(fieldsLenght / columns)
      const matrix = []
      let i = 0
      for (let r = 0; r < rows; r++) {
        const row = []
        for (let c = 0; c < columns; c++) {
          if (i >= fieldsLenght) {
            break
          }
          row.push(fieldArray[i])
          i++
        }
        matrix.push(row)
      }
      return matrix
    },
    fieldFrom (template) {
      return this.field.value[template.id]
    },
    cssClass (template) {
      if (template.annotation.style) {
        const cssStyle = template.annotation.style
        const cssClass = {}
        cssClass['' + cssStyle] = true
        return cssClass
      } else {
        return {}
      }
    }
  },
  computed: {
    fieldsMatrix () {
      const rows = this.template.annotation.rowCount
      if (rows > 0) {
        return this.columnMatrix()
      }
      const rowFieldsCount = this.template.annotation.rowFieldsCount
      if (rowFieldsCount > 0) {
        return this.gridMatrix()
      }

      const matrix = []
      this.template.defaultValue.groupFields.forEach(field => {
        matrix.push([field])
      })
      return matrix
    }
  }
}
</script>

<style scoped>
.title {
  padding-top: 2em;
}
</style>
