<template>
  <router-view/>
</template>

<script>
export default {
  name: 'Integrator Portal Application',
  async created () {
    await this.$store.dispatch('unit/getCurrentUnit')
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
td {
  word-break: break-word;
}
</style>
