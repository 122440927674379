<template>
  <div class="card mb-1 pointer" v-for="project in projects.list" :key="project.id">
    <header class="card-header">
      <p class="card-header-title">
        {{project.number}} {{project.name}}
      </p>
    </header>
    <div class="card-content">
      <div class="media">
        <div class="media-left">
          <figure class="image is-96x96" v-if="project.settings.imageUrl">
            <img :src="project.settings.imageUrl" alt="{{project.name}}">
          </figure>
        </div>
        <div class="media-content">
          <BaseAddress :address="project.address"></BaseAddress>
        </div>
      </div>
    </div>
    <footer class="card-footer">
      <router-link :to="{name: 'MomDocumentationDashboard', params: { unitId: unitId, projectId: project.id }}"  class="card-footer-item">
        {{$filters.translate('FDV')}}
      </router-link>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'ProjectsList',
  props: {
    unitId: {
      required: true,
      type: String
    }
  },
  computed: {
    projects () {
      return this.$store.state.projects.activeProjects
    }
  },
  async created () {
    await this.$store.dispatch('projects/getActiveProjects', { unitId: this.unitId })
  }
}
</script>

<style scoped>

</style>
