import Unit from '@/pages/unit/Unit'
import Project from '@/pages/project/Project'
import Projects from '@/pages/project/Projects'
import ProjectsList from '@/pages/project/ProjectsList'
import ProjectQRCode from '@/pages/project/qrcode/ProjectQRCode'
import MomDocumentation from '@/pages/project/mom/MomDocumentation'
import MomDocumentationDashboard from '@/pages/project/mom/MomDocumentationDashboard'
import GeneralElement from '@/pages/project/mom/GeneralElement'
import BuildingElement from '@/pages/project/mom/BuildingElement'
import NotFound from '@/pages/NotFound'
import Login from '@/pages/Login'
import Test from '@/pages/test/Test'
import TestSignature from '@/pages/test/TestSignature'
import TestFields from '@/pages/test/TestFields'

import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/qrcode/:unitId/projects/:projectId',
    name: 'ProjectQrCode',
    component: ProjectQRCode
  },
  {
    path: '/reader/:unitId',
    name: 'Unit',
    component: Unit,
    children: [
      {
        path: 'projects',
        name: 'Projects',
        component: Projects,
        props: true,
        children: [
          {
            path: 'list',
            name: 'ProjectsList',
            component: ProjectsList,
            props: true
          },
          {
            path: ':projectId',
            name: 'Project',
            component: Project,
            props: true,
            children: [
              {
                path: 'mom',
                name: 'MomDocumentation',
                component: MomDocumentation,
                props: true,
                children: [
                  {
                    path: 'dashboard',
                    name: 'MomDocumentationDashboard',
                    component: MomDocumentationDashboard,
                    props: true
                  },
                  {
                    path: 'general/:generalElementId',
                    name: 'GeneralElement',
                    component: GeneralElement,
                    props: true
                  },
                  {
                    path: 'building/:buildingElementId',
                    name: 'BuildingElement',
                    component: BuildingElement,
                    props: true
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/test',
    name: 'Test',
    component: Test,
    children: [
      {
        path: 'signature',
        name: 'TestSignature',
        component: TestSignature
      },
      {
        path: 'fields',
        name: 'TestFields',
        component: TestFields
      }
    ]
  }
]

export default createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHashHistory(),
  routes // short for `routes: routes`
})
