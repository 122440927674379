<template>
  <section class="hero is-primary is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered">
          <div>
            <div v-if="selectBusinessUnit">
              <div class="card mb-1 pointer" v-for="unit in units" :key="unit.id" @click="handleLoginWithUnit(unit)">
                <header class="card-header">
                  <p class="card-header-title">
                    {{unit.organization.name}}
                  </p>
                </header>
                <div class="card-content">
                  <div class="media">
                    <div class="media-left">
                      <figure class="image is-96x96">
                        <img :src="unit.logoUrl" alt="{{unit.organization.name}}">
                      </figure>
                    </div>
                    <div class="media-content">
                      <BaseAddress :address="unit.address"></BaseAddress>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <form class="box" v-if="!selectBusinessUnit">
              <div class="field">
                <label for="email" class="label">Email</label>
                <div class="control has-icons-left">
                  <input id="email" type="email" placeholder="e.g. bobsmith@gmail.com" class="input" required v-model="user.username">
                  <span class="icon is-small is-left">
                  <i class="fa fa-envelope"></i>
                </span>
                </div>
              </div>
              <div class="field">
                <label for="password" class="label">Password</label>
                <div class="control has-icons-left">
                  <input id="password" type="password" placeholder="*******" class="input" required v-model="user.password">
                  <span class="icon is-small is-left">
                  <i class="fa fa-lock"></i>
                </span>
                </div>
              </div>
              <div class="field" v-if="!loading">
                <button class="button is-success" type="button" @click="handleLogin(user)">
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Login',
  data () {
    return {
      loading: false,
      selectBusinessUnit: false,
      units: [],
      message: '',
      user: {
        username: '',
        password: ''
      }
    }
  },
  computed: {
    loggedIn () {
      return this.$store.state.auth.status.loggedIn
    }
  },
  created () {
    if (this.loggedIn) {
      console.log('Logging')
    }
  },
  methods: {
    handleLogin (user) {
      this.loading = true
      this.$store.dispatch('auth/login', user).then(
        (authenticationResult) => {
          if (authenticationResult.resultKey === 'SELECT_BUSINESS_UNIT') {
            this.selectBusinessUnit = true
            this.units = authenticationResult.businessUnits
          }
          if (authenticationResult.resultKey === 'SUCCESS') {
            this.loginSuccess(authenticationResult.unit)
          }
        },
        (error) => {
          this.loading = false
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        })
    },
    handleLoginWithUnit (unit) {
      this.loading = true
      this.$store.dispatch('auth/loginWithUnit', { user: this.user, unitId: unit.id }).then(
        (authenticationResult) => {
          if (authenticationResult.resultKey === 'SUCCESS') {
            this.loginSuccess(authenticationResult.unit)
          }
        },
        (error) => {
          this.loading = false
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        })
    },
    loginSuccess (unit) {
      this.$store.commit('unit/setUnit', unit)
      this.$router.push('/reader/' + unit.id + '/projects/list')
    }
  }
}
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
