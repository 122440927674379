<template>
  <div class="card mb-2">
    <header class="card-header">
      <p class="card-header-title">
        <span class="tag is-light is-large">
          {{element?.element.code.value}}
        </span>
        {{element?.element.definition.name}}
      </p>
    </header>
    <div class="card-content has-text-centered is-size-2" v-if="element">
      <router-link :to="{name: 'BuildingElement', params: {buildingElementId: element.element.id}}">
        {{link}}
      </router-link>
    </div>
    <div class="card-content has-text-centered is-size-2" v-else>
      <a class="has-text-grey-lighter">
        {{link}}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BuildingElementLink',
  props: {
    element: {
      required: false
    },
    link: {
      required: true,
      type: String
    }
  }
}
</script>

<style scoped>

</style>
