<template>
  <div v-for="(buildingElement, buildingElementId) in list" :key="buildingElementId">
    <router-link :to="{name: 'BuildingElement', params: {buildingElementId: buildingElementId}}">
      <BuildingElementCard :element="buildingElement"></BuildingElementCard>
    </router-link>
  </div>
</template>

<script>
import BuildingElementCard from '@/components/mom/BuildingElementCard'
export default {
  name: 'BuildingElementList',
  components: { BuildingElementCard },
  props: {
    list: {
      required: true
    }
  }
}
</script>

<style scoped>

</style>
