<template>
  Not found
</template>

<script>
export default {
  name: 'PageNotFound'
}
</script>

<style scoped>

</style>
