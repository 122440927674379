<template>
  <div class="panel">
    <div class="panel-heading">
      <nav class="level">
        <!-- Left side -->
        <div class="level-left">
          <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <li>
                <a>
                  {{$filters.translate('READ_ACCESS_USERS')}}
                </a>
              </li>
              <li>
                <router-link :to="{name: 'MomDocumentationDashboard'}">
                  {{$filters.translate('FDV')}}
                </router-link>
              </li>
              <li>
              </li>
            </ul>
          </nav>
        </div>
        <!-- Right side -->
        <div class="level-right">
          <p class="level-item is-size-3">
            <a :href="printPdfUrl()" target="_blank">
              <font-awesome-icon icon="print" />
              {{$filters.translate('PRINT')}}
            </a>
          </p>
        </div>
      </nav>

    </div>
  </div>
  <router-view :key="$route.fullPath"/>
</template>

<script>
export default {
  name: 'MomDocumentation',
  props: {
    unitId: {
      required: true,
      type: String
    },
    projectId: {
      required: true,
      type: String
    }
  },
  methods: {
    printPdfUrl () {
      const ters = this.$store.getters['project/projectPdfUrl']({ unitId: this.unitId, projectId: this.projectId })
      console.log(ters)
      return ters
    }
  },
  async created () {
    await this.$store.dispatch('project/getMomDocumentation', { unitId: this.unitId, projectId: this.projectId })
  }
}
</script>

<style scoped>

</style>
