import axios from 'axios'

const state = () => ({

})

const getters = {
  container: (state) => (fsContext) => {
    const contextContainer = state[fsContext.name]
    return contextContainer && contextContainer[fsContext.relationId]
  },
  multiStructure: (state) => ({ contextName, relationIds }) => {
    const contextContainer = state[contextName]
    const multiStructure = {}
    if (contextContainer) {
      relationIds.forEach(relationId => {
        multiStructure[relationId] = contextContainer[relationId]
      })
    }
    return multiStructure
  }
}

const actions = {
  getContainer ({ commit }, fsContext) {
    console.info('Gets Container Started')
    return axios.get(`/filesystems/${fsContext.name}/${fsContext.relationId}/structure`).then((response) => {
      console.info('Gets Container Completed')
      commit('setContainerStructure', { container: response.data, contextName: fsContext.name, relationId: fsContext.relationId })
    })
  },
  getMultiStructure ({ commit }, { contextName, relationIds }) {
    return axios.post(`/filesystems/${contextName}/multi-structure`, relationIds).then((response) => {
      commit('setContainersStructure', { container: response.data, contextName: contextName, relationIds: relationIds })
    })
  }
}

const mutations = {
  setContainerStructure (state, data) {
    installStructure(state, data)
  },
  setContainersStructure (state, data) {
    const contextName = data.contextName
    data.relationIds.forEach(relationId => {
      const container = data.container.map[relationId]
      installStructure(state, { container, contextName, relationId })
    })
  }
}

function installStructure (state, data) {
  let contextState = state[data.contextName]
  if (!contextState) {
    contextState = state[data.contextName] = {}
  }
  contextState[data.relationId] = data.container
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
