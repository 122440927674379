import axios from 'axios'

const state = () => ({
  defaultLocale: 'no',
  i18N: {
    no: {}
  }
})

const getters = {
  text: (state) => (text, locale) => {
    locale = locale || state.defaultLocale
    let localizations = state.i18N[locale]
    if (!localizations) {
      localizations = state.i18N[state.defaultLocale]
    }
    return localizations[text]
  }
}

const actions = {
  getLocalization ({ commit }, locale) {
    locale = locale || state().defaultLocale
    return axios.get(`/translate/${locale}`).then((response) => {
      commit('setLocalizations', { localization: response.data, locale })
    })
  }
}

const mutations = {
  setLocalizations (state, { localization, locale }) {
    state.i18N[locale] = localization
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
