<template>
  <div class="container mt-1 pt-2" v-if="unit">
    <header class="heading">
      <div class="columns">
        <div class="column is-one-fifth">
          <figure class="image">
            <img v-bind:src="unit.logoUrl" class="unit-image" />
          </figure>
        </div>
        <div class="column">
          <div class="is-pulled-right">
            <b>{{unit.organization.name}}</b>
            <BaseAddress :address="unit.address"></BaseAddress>
          </div>
        </div>
      </div>
    </header>
  </div>
  <div class="container mt-1">
    <router-view/>
  </div>
  <hr/>
</template>

<script>
export default {
  name: 'Unit',
  props: {
    unitId: {
      required: true,
      type: String
    }
  },
  computed: {
    unit () {
      let unit = this.$store.state.unit.current
      if (!unit) {
        var session = this.$store.state.auth.session
        if (session) {
          unit = session.unit
        }
      }
      return unit
    }
  },
  mounted () {
    if (!this.unit) {
      // this.$router.push('/login')
    }
  }
}
</script>

<style scoped>
  .unit-image {
    width:100%;
    max-width:600px;
  }
</style>
