<template>
  <p>
    <b v-if="template.definition.name">
      {{template.definition.name}}
    </b>
    <span v-if="template.definition.prefix">
      {{template.definition.prefix}}
    </span>

    <span v-if="template.definition.prefix || template.definition.suffix">
      <span v-if="field.value" class="field-value">
        {{field.value}}
      </span>
      <span v-else class="field-value-empty-short">
        <input type="text" class="dotted" disabled>
      </span>
    </span>

    <span v-if="!(template.definition.prefix && template.definition.suffix)">
      <span v-if="field.value" class="field-value">
        {{field.value}}
      </span>
      <span v-else class="field-value-empty-full">
        <input type="text" class="wide100 dotted" disabled>
      </span>
    </span>

    <span v-if="template.definition.suffix">
      {{template.definition.suffix}}
    </span>
  </p>
  <p v-if="template.definition.description">
    <small>
      {{template.definition.description}}
    </small>
  </p>
</template>

<script>
export default {
  name: 'FieldShortText',
  components: { },
  props: {
    template: {
      type: Object
    },
    field: {
      type: Object
    }
  }
}
</script>

<style scoped>
.field-value-empty-short {
}
.field-value-empty-full {
}
.field-value {
}
.wide100 {
  width: 100%;
}
.dotted {
  border:0;
  border-bottom: 1px dotted;
}

</style>
