<template>

    <div class="columns is-multiline is-mobile pb-2" v-for="(record, recordId) in structure.records" :key="recordId">
      <div class="column is-one-quarter" v-if="!hideImages">
        <figure class="image" v-if="isImage(record)">
          <img alt="{{record.name}}" :src="record.url">
        </figure>
        <figure class="image is-64x64" v-else>
          <img src="https://bulma.io/images/placeholders/128x128.png">
        </figure>
      </div>
      <div class="column">
        <article class="media">
          <div class="media-content">
            <p>
              <strong>{{record.name}}</strong>
              <br/>
              {{record.description}}
              <small>
                <BaseDate :timestamp="record.createdAt"></BaseDate>
              </small>
            </p>
          </div>
        </article>
      </div>
      <div class="column is-one-quarter">
        <a :href="record.url" v-text="record.name"/>
      </div>
    </div>

</template>

<script>
export default {
  name: 'FsContainer',
  components: { },
  props: {
    structure: {
      required: true
    },
    hideImages: {
      required: false,
      type: Boolean
    }
  },
  data () {
    return {
      imageRegex: new RegExp('(png|jpg|jpeg|gif|bmp|ico|svg|PNG|JPG|JPEG|GIF|BMP|ICO|SVG)$')
    }
  },
  methods: {
    isImage ({ name }) {
      return this.imageRegex.test(name)
    }
  },
  computed: {
  }
}
</script>

<style scoped>
</style>
