<template>
  <textarea name="" id="jsonText" :cols="tCols" :rows="tRows" v-model="json"></textarea>
</template>

<script>
export default {
  name: 'BaseJson',
  props: {
    value: {
      type: String
    },
    cols: {
      type: Number
    },
    rows: {
      type: Number
    }
  },
  computed: {
    json () {
      return JSON.stringify(this.value, undefined, 4)
    },
    tCols () {
      return this.cols > 0 ? this.cols : 20
    },
    tRows () {
      return this.rows > 0 ? this.rows : 5
    }
  }
}
</script>

<style scoped>
  textarea {
    width: 100%;
    font-family: "Lucida Console", Monaco, monospace;
    font-size: 0.8rem;
    line-height: 1.2;
  }
</style>
