<template>
  <h5 class="title is-5" v-if="definition.name">
    {{definition.name}}
  </h5>
  <h6 class="title is-6" v-if="definition.description">
    {{definition.description}}
  </h6>
</template>

<script>
export default {
  name: 'FieldDefinition',
  props: {
    definition: {
      type: Object
    }
  }
}
</script>

<style scoped>

</style>
