<template>
  <div class="columns">
    <div class="column">
      <BuildingElementLink :element="neighbors[0]" :link="'<<'"></BuildingElementLink>
    </div>
    <div class="column is-half">
      <div v-if="element">
        <BuildingElementCard :element="element"></BuildingElementCard>
      </div>
    </div>
    <div class="column">
      <BuildingElementLink :element="neighbors[1]" :link="'>>'"></BuildingElementLink>
    </div>
  </div>
  <div v-for="documentationPiece in element.pieces" :key="documentationPiece.id">
    <div class="panel" v-if="showPiece(documentationPiece)">
      <div class="panel-heading">
        {{$filters.translate('FDV_BUILDING_ELEMENT_SPEC_'+documentationPiece.informationPiece.type.definition.name)}}
      </div>
      <div class="panel-block">
        <div class="box" v-if="documentationPiece.definition.description">
          <div class="ml-5 content">
            <vue3-markdown-it :source="documentationPiece.definition.description" />
          </div>
        </div>
      </div>
      <div class="panel-block" v-if="documentationPieceHasFiles(documentationPiece)">
        <div class="box">
          <FsContainer :structure="files[documentationPiece.id]"></FsContainer>
        </div>
      </div>
      <div class="panel-block" v-if="documentationPieceHasLinks(documentationPiece)">
        <div class="box">
          <base-link-list :list="links[documentationPiece.id].links"></base-link-list>
        </div>
      </div>
      <div class="panel-block" v-if="documentationPieceHasProducts(documentationPiece)">
        <div class="box">
          <table class="table">
            <thead>
            <tr>
              <th></th>
              <th>{{$filters.translate('NAME')}}</th>
              <th>{{$filters.translate('NUMBER')}}</th>
              <th>{{$filters.translate('COMPANY')}}</th>
              <th>{{$filters.translate('FILES')}}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="product in products.list" :key="product.id">
              <td>
                <figure class="image is-128x128">
                  <img :src="productImage(product)" :alt="product.name">
                </figure>
              </td>
              <td>
                {{product.name}}
              </td>
              <td>
                {{product.number}}
              </td>
              <td>
                {{product.owner.name}}
              </td>
              <td>
                <div v-if="productHasFiles(product)">
                  <FsContainer :structure="productFiles[product.id]" :hide-images="true"></FsContainer>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BuildingElementCard from '@/components/mom/BuildingElementCard'
import BuildingElementLink from '@/components/mom/BuildingElementLink'
import FsContainer from '@/components/fs/FsContainer'

export default {
  name: 'BuildingElement',
  components: { BuildingElementCard, BuildingElementLink, FsContainer },
  props: {
    unitId: {
      required: true,
      type: String
    },
    projectId: {
      required: true,
      type: String
    },
    buildingElementId: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      fsContextName: 'mom-project-building-documentation',
      fsProductContextName: 'product',
      files: {},
      links: {},
      products: {},
      productFiles: {}
    }
  },
  computed: {
    element () {
      const id = this.buildingElementId
      return this.$store.state.project.documentation?.building[id]
    },
    neighbors () {
      const buildingElements = this.$store.state.project.documentation.building
      let current = null
      let next = null
      let previous = null
      let lastIteration = false
      for (const elementId in buildingElements) {
        if (lastIteration) {
          next = buildingElements[elementId]
          break
        } else {
          previous = current
          current = buildingElements[elementId]
          lastIteration = elementId === this.buildingElementId
        }
      }
      return [previous, next]
    }
  },
  methods: {
    documentationPieceHasFiles (piece) {
      const files = this.files[piece.id]
      return files && !files.empty
    },
    documentationPieceHasLinks (piece) {
      const linksHolder = this.links[piece.id]
      return linksHolder && !linksHolder.empty
    },
    documentationPieceHasProducts (piece) {
      return piece.informationPiece.type.definition.name === 'PRODUCT_INFORMATION' && !this.products.empty
    },
    productHasFiles (product) {
      const files = this.productFiles[product.id]
      return files && !files.empty
    },
    showPiece (piece) {
      return !piece.overview.empty
    },
    productImage (product) {
      const thumbnailUrl = product.thumbnailUrl
      if (thumbnailUrl) {
        return thumbnailUrl
      } else {
        if (this.productHasFiles(product)) {
          const files = this.productFiles[product.id]
          return this.anyProductImage(files)
        }
      }
    },
    anyProductImage (files) {
      const imageExts = ['jpg', 'jpeg', 'gif', 'png', 'eps', 'tiff']
      let productImage = '/img/no-image.png'
      const records = Object.values(files.records)
      if (records) {
        for (let i = 0; i < records.length; i++) {
          if (imageExts.includes(records[i].extension.toLowerCase())) {
            productImage = records[i].url
            break
          }
        }
      }
      return productImage
    }
  },
  async created () {
    // Read files
    const element = this.$store.state.project.documentation?.building[this.buildingElementId]
    const piecesIds = Object.values(element.pieceIds)
    let fsMultiContext = { contextName: this.fsContextName, relationIds: piecesIds }
    await this.$store.dispatch('fs/getMultiStructure', fsMultiContext)
    this.files = this.$store.getters['fs/multiStructure'](fsMultiContext)
    // Links
    await this.$store.dispatch('project/getBuildingElementLinks', { unitId: this.unitId, projectId: this.projectId, elementId: element.id })
    this.links = this.$store.getters['project/buildingElementLinks'](element.id)
    // Products
    await this.$store.dispatch('project/getBuildingElementProducts', { unitId: this.unitId, projectId: this.projectId, elementId: element.id })
    this.products = this.$store.getters['project/buildingElementProducts'](element.id)

    const productIds = this.products.list.map(product => product.id)
    fsMultiContext = { contextName: this.fsProductContextName, relationIds: productIds }
    await this.$store.dispatch('fs/getMultiStructure', fsMultiContext)
    this.productFiles = this.$store.getters['fs/multiStructure'](fsMultiContext)
  }
}
</script>

<style scoped>
.panel-block {
  display: block;
}
</style>
