<template>
  <div class="columns">
    <div class="column">
      <GeneralElementLink :element="neighbors[0]" :link="'<<'"></GeneralElementLink>
    </div>
    <div class="column is-half">
      <div v-if="element">
        <GeneralElementCard :element="element"></GeneralElementCard>
      </div>
    </div>
    <div class="column">
      <GeneralElementLink :element="neighbors[1]" :link="'>>'"></GeneralElementLink>
    </div>
  </div>
  <div class="box" v-if="element.definition.description">
    <div class="ml-5">
      <vue3-markdown-it :source="element.definition.description" />
    </div>
  </div>
  <div class="box" v-if="files && !files.empty">
    <FsContainer :structure="files"></FsContainer>
  </div>
  <div class="box" v-if="links?.links.length > 0">
    <base-link-list :list="links.links"></base-link-list>
  </div>
</template>

<script>
import GeneralElementCard from '@/components/mom/GeneralElementCard'
import GeneralElementLink from '@/components/mom/GeneralElementLink'
import FsContainer from '@/components/fs/FsContainer'

export default {
  components: { GeneralElementCard, GeneralElementLink, FsContainer },
  props: {
    unitId: {
      required: true,
      type: String
    },
    projectId: {
      required: true,
      type: String
    },
    generalElementId: {
      required: true,
      type: String
    }
  },
  name: 'GeneralElement',
  data () {
    return {
      fsContextName: 'mom-project-general-documentation',
      files: {},
      links: {}
    }
  },
  computed: {
    element () {
      const id = this.generalElementId
      return this.$store.state.project.documentation?.general[id]
    },
    neighbors () {
      const generalElements = this.$store.state.project.documentation.general
      let current = null
      let next = null
      let previous = null
      let lastIteration = false
      for (const elementId in generalElements) {
        if (lastIteration) {
          next = generalElements[elementId]
          break
        } else {
          previous = current
          current = generalElements[elementId]
          lastIteration = elementId === this.generalElementId
        }
      }
      return [previous, next]
    },
    documentationElementId () {
      return this.element?.id
    }
  },
  async created () {
    // Read files
    const fsContext = { name: this.fsContextName, relationId: this.documentationElementId }
    await this.$store.dispatch('fs/getContainer', fsContext)
    this.files = this.$store.getters['fs/container'](fsContext)
    // LInks
    await this.$store.dispatch('project/getGeneralElementLinks', { unitId: this.unitId, projectId: this.projectId, elementId: this.documentationElementId })
    this.links = this.$store.getters['project/generalElementLinks'](this.documentationElementId)
  }
}
</script>

<style scoped>

</style>
