import axios from 'axios'

const session = JSON.parse(localStorage.getItem('session'))
const initialState = session
  ? { status: { loggedIn: true }, session }
  : { status: { loggedIn: false }, session: null }

export default {
  namespaced: true,
  state: initialState,
  actions: {
    login ({ commit }, user) {
      return axios.post('/portal/authenticate', user).then(
        result => {
          const authenticationResult = result.data
          if (authenticationResult.resultKey === 'SUCCESS') {
            commit('loginSuccess', authenticationResult)
          }
          return Promise.resolve(authenticationResult)
        },
        error => {
          commit('loginFailure')
          return Promise.reject(error)
        }
      )
    },
    loginWithUnit ({ commit }, userWithUnit) {
      return axios.post('/portal/authenticate/' + userWithUnit.unitId, userWithUnit.user).then(
        result => {
          const authenticationResult = result.data
          if (authenticationResult.resultKey === 'SUCCESS') {
            commit('loginSuccess', authenticationResult)
          }
          return Promise.resolve(authenticationResult)
        },
        error => {
          commit('loginFailure')
          return Promise.reject(error)
        }
      )
    }
  },
  mutations: {
    loginSuccess (state, session) {
      const token = session.token
      localStorage.setItem('token', token)
      axios.defaults.headers.common.Authorization = 'Bearer: ' + token

      state.status.loggedIn = true
      state.session = session
      localStorage.setItem('session', JSON.stringify(session))
    },
    loginFailure (state) {
      state.status.loggedIn = false
      state.session = null
    }
  }
}
