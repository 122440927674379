<template>
  <div class="container">
    <div class="box">
      <h1 class="title">
        {{nelfoContractDocument.definition.name}}
      </h1>
      <h2 class="subtitle">
        {{nelfoContractDocument.definition.description}}
      </h2>

      <div v-for="templateField in nelfoContractDocument.entity.template.fieldTemplates" :key="templateField.id">
        <div v-if="templateField.metadata.type === 'Group'">
          <FieldGroup :template="templateField" :field="fieldValue(templateField)"></FieldGroup>
        </div>
        <div v-if="templateField.metadata.type === 'List'">
          <FieldList :template="templateField" :field="fieldValue(templateField)"></FieldList>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="panel">
        <div class="panel-heading">
          <button @click="save('image/jpeg')">Save</button>
          <button @click="clear">Clear</button>
          <button @click="undo">Undo</button>
          <button @click="addWaterMark">addWaterMark</button>
          <button @click="fromDataURL">fromDataURL</button>
          <button @click="handleDisabled">disabled</button>
        </div>
        <div class="panel-block border">
          <Vue3Signature  ref="signature1" :sigOption="state.option" :w="'1100px'" :h="'150px'"
                          :disabled="state.disabled" class="example"></Vue3Signature>

        </div>
      </div>
    </div>
  </div>
  <div class="container">
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import nelfoContractDocument from '@/pages/test/nelfo-contract-document.json'
import FieldGroup from '../../components/elasticentity/FieldGroup'
import FieldList from '../../components/elasticentity/FieldList'

const state = reactive({
  count: 0,
  option: {
    penColor: 'rgb(0, 0, 0)',
    backgroundColor: 'rgb(255,255,255)'
  },
  disabled: false
})

const signature1 = ref(null)
const save = (t) => {
  console.log(signature1.value.save(t))
}

const clear = () => {
  signature1.value.clear()
}
const undo = () => {
  signature1.value.undo()
}

const addWaterMark = () => {
  signature1.value.addWaterMark({
    text: 'mark text', // watermark text, > default ''
    font: '20px Arial', // mark font, > default '20px sans-serif'
    style: 'all', // fillText and strokeText,  'all'/'stroke'/'fill', > default 'fill
    fillStyle: 'red', // fillcolor, > default '#333'
    strokeStyle: 'blue', // strokecolor, > default '#333'
    x: 100, // fill positionX, > default 20
    y: 200, // fill positionY, > default 20
    sx: 100, // stroke positionX, > default 40
    sy: 200 // stroke positionY, > default 40
  })
}

const handleDisabled = () => {
  state.disabled = !state.disabled
}

const fieldValue = (field) => {
  return nelfoContractDocument.entity.fields.values[field.id]
}
</script>

<style scoped>
  .example{
    margin: 0 auto;

  }
  .border {
    border: #2c3e50 1px solid;
  }
</style>
