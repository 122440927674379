<template>
  <FieldDefinition definition="template.definition"></FieldDefinition>
  <p>
    {{template.defaultValue.value}}
  </p>
</template>

<script>
export default {
  name: 'fieldLabel',
  props: {
    template: {
      type: Object
    },
    field: {
      type: Object
    }
  }
}
</script>

<style scoped>

</style>
