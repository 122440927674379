<template>
  <article class="media">
    <div class="media-content">
      <div class="content">
        <p>
          <strong>{{link.definition.name}}</strong>
          <br>
          <a :href="link.value">{{link.value}}</a>
          <br/>
          {{link.definition.description}}
        </p>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: 'BaseLink',
  props: {
    link: {
      required: true
    }
  }
}
</script>

<style scoped>

</style>
