<template>
  <div v-for="link in list" :key="link.id">
    <base-link :link="link"></base-link>
  </div>
</template>

<script>
export default {
  name: 'BaseLinkList',
  components: {},
  props: {
    list: {
      required: true
    }
  }
}
</script>

<style scoped>

</style>
