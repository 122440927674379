import { createApp } from 'vue'
import App from './App.vue'
import '@/assets/sass/main.sass'
import router from '@/router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from '@/store'
import VueMarkdownIt from 'vue3-markdown-it'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Vue3Signature from 'vue3-signature'

library.add(fas)

function getQueryParameterByName (name, url = window.location.href) {
  name = name.replace(/[[\]]/g, '\\$&')
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
  const results = regex.exec(url)
  if (!results) return getLocalStoreValue(name)
  if (!results[2]) return getLocalStoreValue(name)
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

const localStorage = window.localStorage
function getLocalStoreValue (name) {
  return localStorage.getItem(name)
}
const tokenKey = 'token'
const token = getQueryParameterByName(tokenKey)
console.log('Got token: ' + token)
localStorage.setItem(tokenKey, token)

axios.defaults.token = token
axios.defaults.baseURL = process.env.VUE_APP_INTEGRATOR_URL
axios.defaults.headers.common.Authorization = 'Bearer: ' + axios.defaults.token

const portalApp = createApp(App)
portalApp.use(router)
portalApp.use(store)
portalApp.use(VueMarkdownIt)
portalApp.use(VueAxios, axios)
portalApp.use(Vue3Signature)

store.dispatch('localization/getLocalization', 'no').then(result => {
  portalApp.mount('#app')
})

portalApp.config.globalProperties.$filters = {
  translate (text) {
    return store.getters['localization/text'](text)
  }
}

const requireComponent = require.context('./components/base/', true, /Base[A-Z]\w+\.(vue|js)$/)
requireComponent.keys().forEach(function (fileName) {
  let baseComponentConfig = requireComponent(fileName)
  baseComponentConfig = baseComponentConfig.default || baseComponentConfig
  const baseComponentName = baseComponentConfig.name || (
    fileName
      .replace(/^.+\//, '')
      .replace(/\.\w+$/, '')
  )
  portalApp.component(baseComponentName, baseComponentConfig)
})
portalApp.component('font-awesome-icon', FontAwesomeIcon)
