<template>
    <div class="card mb-2">
      <header class="card-header">
        <p class="card-header-title">
        <span class="tag is-light is-large">
          {{element.element.code.value}}
        </span>
          {{element.element.definition.name}}
        </p>
        <button class="card-header-icon" aria-label="more options">
      <span class="icon">
        <i class="fas fa-angle-down" aria-hidden="true"></i>
      </span>
        </button>
      </header>
      <div class="card-content">
        <div class="content">
          {{element.element.definition.description}}
        </div>
        <nav class="level">
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Filer</p>
              <p class="">{{ element.overview.filesCount }}</p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Lenker</p>
              <p class="">{{ element.overview.linksCount }}</p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Opgaver</p>
              <p class="">{{ element.overview.eventsCount }}</p>
            </div>
          </div>
        </nav>
      </div>
    </div>
</template>

<script>
export default {
  name: 'GeneralElementCard',
  props: {
    element: {
      required: true
    }
  }
}
</script>

<style scoped>

</style>
