<template>
  <div v-if="project">
    <section class="hero is-primary is-small">
      <div class="hero-body">
        <span class="is-pulled-right">
          <figure class="image is-128x128 is-right">
            <img :src="project.settings.imageUrl">
          </figure>
        </span>
        <p class="title">
          <i v-if="project.number">{{project.number}}</i> {{project.name}}
        </p>
        <p class="subtitle">
          <BaseAddress :address="project.address"></BaseAddress>
        </p>
      </div>
    </section>
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'Project',
  props: {
    unitId: {
      required: true,
      type: String
    },
    projectId: {
      required: true,
      type: String
    }
  },
  computed: {
    unit () {
      return this.$store.state.unit.current
    },
    project () {
      return this.$store.state.project.current
    }
  },
  async created () {
    await this.$store.dispatch('project/getProject', { unitId: this.unitId, projectId: this.projectId })
  }
}
</script>

<style scoped>

</style>
