<template>
  <h3 class="title is-3" v-if="template.definition.name">
    {{template.definition.name}}
  </h3>
  <h4 class="tile is-4" v-if="template.definition.description">
    {{template.definition.description}}
  </h4>
</template>

<script>
export default {
  name: 'FieldList',
  props: {
    template: {
      type: Object
    },
    field: {
      type: Object
    }
  }
}
</script>

<style scoped>

</style>
