<template>
  <span :title="humanFriendlyDate()">
    {{ diffForHuman() }}
  </span>
</template>

<script>
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import localizedDate from 'dayjs/plugin/localizedFormat'
dayjs.extend(relativeTime)
dayjs.extend(localizedDate)

export default {
  name: 'BaseDate',
  props: {
    timestamp: {
      required: true,
      type: Number
    }
  },
  methods: {
    humanFriendlyDate () {
      dayjs.unix(this.timestamp).fromNow()
    },
    diffForHuman () {
      dayjs.unix(this.timestamp).format('YYYY-MM-DD')
    }
  }
}
</script>

<style scoped>

</style>
