import axios from 'axios'

const state = () => ({
  current: null
})

// getters
const getters = {
}

// actions
const actions = {
  getCurrentUnit ({ commit }) {
    return axios.get('/business/units/current').then((response) => {
      commit('setUnit', response.data)
    })
  }
}

// mutations
const mutations = {
  setUnit (state, unit) {
    console.log(unit.id)
    state.current = unit
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
