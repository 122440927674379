import axios from 'axios'

const state = () => ({
  activeProjects: []
})

const getters = {
  activeProjects: (state) => () => {
    return state.activeProjects
  }
}

const actions = {
  getActiveProjects ({ commit }, { unitId }) {
    return axios.get(`/business/${unitId}/projects/read-access`).then((response) => {
      commit('setActiveProjects', response.data)
    })
  }
}

const mutations = {
  setActiveProjects (state, projects) {
    state.activeProjects = projects
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
