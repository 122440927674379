import { createStore, createLogger } from 'vuex'
import localization from './modules/localization'
import unit from './modules/unit'
import project from './modules/project'
import projects from './modules/projects'
import fs from './modules/fs'
import auth from './modules/auth'

const debug = process.env.NODE_ENV !== 'production'

export default createStore({
  modules: {
    auth, unit, project, projects, fs, localization
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})
