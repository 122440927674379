<template>
  <div>{{address.line1}}</div>
  <div>{{address.line2}}</div>
  <div>{{address.postalCode}} {{address.city}}</div>
</template>

<script>
export default {
  name: 'BaseAddress',
  props: {
    address: {
      required: true
    }
  }
}
</script>

<style scoped>

</style>
