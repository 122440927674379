<template>
  <div class="panel">
    <div class="panel-heading">
      {{ $filters.translate('GENERAL_ELEMENTS_ASSIGNED') }}
    </div>
  </div>
  <div v-if="documentation?.general">
    <general-element-list :list="documentation.general"></general-element-list>
  </div>
  <div class="panel">
    <div class="panel-heading">
      {{ $filters.translate('BUILDING_ELEMENTS_ASSIGNED') }}
    </div>
  </div>
  <div v-if="documentation?.building">
    <building-element-list :list="documentation.building"></building-element-list>
  </div>
</template>

<script>
import BuildingElementList from '@/components/mom/BuildingElementList'
import GeneralElementList from '@/components/mom/GeneralElementList'

export default {
  name: 'MomDocumentationDashboard',
  components: {
    BuildingElementList,
    GeneralElementList
  },
  props: {
    unitId: {
      required: true,
      type: String
    },
    projectId: {
      required: true,
      type: String
    }
  },
  computed: {
    documentation () {
      return this.$store.state.project.documentation
    }
  },
  methods: {
  },
  created () {
  }
}
</script>

<style lang="scss">
  @import '@/assets/sass/pages/mom-documentation.scss';
</style>
