<template>
  <div v-for="(generalElement, generalElementId) in list" :key="generalElementId">
    <router-link :to="{name: 'GeneralElement', params: {generalElementId: generalElementId}}">
      <GeneralElementCard :element="generalElement"></GeneralElementCard>
    </router-link>

  </div>
</template>

<script>
import GeneralElementCard from '@/components/mom/GeneralElementCard'
export default {
  name: 'GeneralElementList',
  components: {
    GeneralElementCard
  },
  props: {
    list: {
      required: true
    }
  }
}
</script>

<style scoped>

</style>
