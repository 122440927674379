<template>
  <section class="hero is-primary is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="card mb-1 pointer" v-if="project">
          <header class="card-header">
            <p class="card-header-title">
              {{project.number}} {{project.name}}
            </p>
          </header>
          <div class="card-content">
            <div class="media">
              <div class="media-left">
                <figure class="image is-250" v-if="project.settings.imageUrl">
                  <img :src="project.settings.imageUrl" alt="{{project.name}}">
                </figure>
              </div>
            </div>
          </div>
          <div class="card-content">
            <div class="media">
              <div class="media-content">
                <BaseAddress :address="project.address"></BaseAddress>
              </div>
            </div>
          </div>
        </div>
        <form class="box">
          <div class="field">
            <label for="email" class="label">
              Registrere/Avregistrere i prosjektet
            </label>
            <div class="control has-icons-left">
              <input id="email" type="email" placeholder="e.g. bobsmith@gmail.com" class="input" required v-model="user.email">
              <span class="icon is-small is-left">
                  <i class="fa fa-envelope"></i>
                </span>
            </div>
          </div>
          <div v-if="!state.checked">
            <button class="button is-success" type="button" @click="checkRegistration(user)">
              Sjekk e-posten
            </button>
          </div>
          <div v-if="state.checked">
            <div v-if="state.signIn">
              <div v-if="!state.finished">
                <button class="button is-success" type="button" @click="handleAction('sign-in')">
                  Ankommet byggeplass
                </button>
              </div>
              <div v-if="state.finished">
                <div>
                  Du er registrert i byggeområde
                </div>
                <button class="button is-warning" type="button" @click="handleAction('sign-out')">
                  Forlatt byggeplass
                </button>
              </div>
            </div>
            <div v-if="state.signOut">
              <div v-if="!state.finished">
                <button class="button is-warning" type="button" @click="handleAction('sign-out')">
                  Forlatt byggeplass
                </button>
              </div>
              <div v-if="state.finished">
                <div>
                  Du har forlatt bygningen
                </div>
                <button class="button is-success" type="button" @click="handleAction('sign-in')">
                  Ankommet byggeplass igjen
                </button>
              </div>
            </div>
            <div v-if="state.error">
              Du har ikke lov til å logge på. Ta kontakt med prosjektlederen din.
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useRoute } from 'vue-router'
import { reactive } from 'vue'
import axios from 'axios'
import store from '@/store'

const route = useRoute()
const unitId = route.params.unitId
const projectId = route.params.projectId

const state = reactive({
  checked: false,
  signOut: false,
  signIn: false,
  error: null,
  finished: false
})
const user = reactive({
  email: ''
})
const project = reactive({})

async function loadProject () {
  await store.dispatch('project/getProjectInfo', { unitId: route.params.unitId, projectId: route.params.projectId })
  Object.assign(project, store.state.project.current)
}

async function checkRegistration (user) {
  await axios.post(`/business/${unitId}/projects/${projectId}/crew-registration/portal/check`, { email: user.email })
    .then((response) => {
      state.checked = true
      if (response.data === 'sign-in') {
        state.signIn = true
      } else if (response.data === 'sign-out') {
        state.signOut = true
      } else {
        state.error = response
      }
    })
    .catch((error) => {
      state.checked = true
      state.error = error
    })
}

async function handleAction (action) {
  await axios.post(`/business/${unitId}/projects/${projectId}/crew-registration/portal/${action}`, { email: user.email })
    .then((response) => {
      switch (action) {
        case 'sign-in':
          state.signIn = true
          state.signOut = false
          break
        case 'sign-out':
          state.signIn = false
          state.signOut = true
          break
      }
      state.finished = true
    })
    .catch((error) => {
      state.error = error
    })
}

loadProject()

</script>

<style scoped>
.image.is-250{width:250px}
</style>
