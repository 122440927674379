<template>
  <FieldDefinition :definition="template.definition"></FieldDefinition>
  <p v-if="field.value" class="content">
    <vue3-markdown-it :source="field.value" />
  </p>
  <p v-else>
    <EmptyFieldText></EmptyFieldText>
  </p>
</template>

<script>
import FieldDefinition from './FieldDefinition'
export default {
  name: 'FieldRichText',
  components: { FieldDefinition },
  props: {
    template: {
      type: Object
    },
    field: {
      type: Object
    }
  }
}
</script>

<style scoped>

</style>
